<template>
	<content-page>
		<template v-slot:content>
			<v-card flat id="glossary">
				<v-card-title>
					{{$t("help.glossary")}}
				</v-card-title>
				<v-card-text>
					<v-simple-table>
						<tbody>
							<tr>
								<td width="30%"><strong>Term</strong></td>
								<td width="70%"><strong>Definition</strong></td>
							</tr>
							<tr>
								<td>Academy of Medical Royal Colleges</td>
								<td width="70%">
									The Academy of Medical Royal Colleges brings together the
									expertise of the medical Royal Colleges and Faculties to
									drive improvement in health and patient care through
									education, training and quality standards. The Academy
									published best practice standards for clinical record
									content, discharge summaries and outpatient letters
									<a
										href="http://www.aomrc.org.uk/publications/reports-guidance/standards-for-the-clinical-structure-and-content-of-patient-records-0713/"
										>http://www.aomrc.org.uk/publications/reports-guidance/standards-for-the-clinical-structure-and-content-of-patient-records-0713/</a
									>
								</td>
							</tr>
							<tr>
								<td>Adverse Event</td>
								<td width="70%">
									Any undesirable experience associated with the use of a
									medical product in a patient.
								</td>
							</tr>
							<tr>
								<td>AHP</td>
								<td width="70%">See Allied Health Professional</td>
							</tr>
							<tr>
								<td>Allied Health Professional</td>
								<td width="70%">
									Allied Health Professionals are healthcare professionals,
									distinct from doctors, nurses, midwives and pharmacists,
									who work as key members of multi-disciplinary, multi-agency
									teams, bringing their rehabilitation focus and specialist
									expertise to the wider skills pool e.g. physiotherapist,
									speech and language therapist, radiographer, occupational
									therapist, podiatrist.
								</td>
							</tr>
							<tr>
								<td>Allergy / Intolerance</td>
								<td width="70%">
									A damaging immune response by the body to a substance,
									especially a particular food, pollen, fur, or dust, to
									which it has become hypersensitive.
								</td>
							</tr>
							<tr>
								<td>Antimicrobial Stewardship</td>
								<td width="70%">
									Antimicrobial stewardship initiatives aim to improve the
									prescribing of all agents, whether they target bacterial,
									viral, mycobacterial or protozoal infections.
								</td>
							</tr>
							<tr>
								<td>Audit Trail</td>
								<td width="70%">
									A record of the changes that have been made to a set of
									data, likely in a database or file.
								</td>
							</tr>
							<tr>
								<td>Authentication</td>
								<td width="70%">
									The process or action of verifying the identity of a user
								</td>
							</tr>
							<tr>
								<td>Automatic Identification</td>
								<td width="70%">
									A process that automatically verifies the identity of a
									patient.
								</td>
							</tr>
							<tr>
								<td>Barcode Technology</td>
								<td width="70%">
									A machine-readable code in the form of numbers and a
									pattern of parallel lines of varying widths.
								</td>
							</tr>
							<tr>
								<td>Benefits Management</td>
								<td width="70%">
									The process of ensuring that the organisation fully
									utilises the benefits afforded to it by any initiative,
									programme or asset.
								</td>
							</tr>
							<tr>
								<td>Benefits Realisation</td>
								<td width="70%">
									The process of utilising benefits from any initiative,
									programme or asset.
								</td>
							</tr>
							<tr>
								<td>BNF</td>
								<td width="70%">British National Formulary.</td>
							</tr>
							<tr>
								<td>Bring Your Own Device (BYOD)</td>
								<td width="70%">
									BYOD, or bring your own device, refers to employees who
									bring their own computing devices, such as smartphones,
									laptops and tablets, to the workplace for use and
									connectivity on the secure corporate network.
								</td>
							</tr>
							<tr>
								<td>British National Formulary</td>
								<td width="70%">
									A pharmaceutical reference book that contains a wide
									spectrum of information and advice on prescribing and
									pharmacology.
								</td>
							</tr>
							<tr>
								<td>Business Continuity</td>
								<td width="70%">
									Collective term for activities, strategies and plans
									designed to ensure continued operations under adverse
									conditions, such as natural disasters, cyber attack or
									pandemic health threats.
								</td>
							</tr>
							<tr>
								<td>Business Owners</td>
								<td width="70%">
									A senior manager with responsibility for the delivery of
									benefits in accordance with a business case to which
									funding is usually attached.
								</td>
							</tr>
							<tr>
								<td>Business-Critical Digital Services</td>
								<td width="70%">
									Any systems or digital processes without which business as
									usual may be able to continue for a short period of time,
									but not indefinitely.
								</td>
							</tr>
							<tr>
								<td>Care Pathway</td>
								<td width="70%">
									A multidisciplinary outline of anticipated care, placed in
									an appropriate timeframe, to help a patient with a specific
									condition or set of symptoms move progressively through a
									clinical experience to positive outcomes.
								</td>
							</tr>
							<tr>
								<td>Care Plan</td>
								<td width="70%">
									An individualised plan to guide all health and care
									professionals involved in delivering a person’s care about
									actions required.
								</td>
							</tr>
							<tr>
								<td>Care Summaries</td>
								<td width="70%">
									A document summarising details of a person’s transition of
									care, as well as certain details about his/her clinical
									condition and current treatments/care plans.
								</td>
							</tr>
							<tr>
								<td>CBT</td>
								<td width="70%">Cognitive behavioural therapy</td>
							</tr>
							<tr>
								<td>CCIO</td>
								<td width="70%">See Chief Clinical Information Officer.</td>
							</tr>
							<tr>
								<td>Change Management</td>
								<td width="70%">
									Collective term for activities designed to bring about
									change; usually, this includes user training, monitoring,
									coaching and other instructive activities with a view of
									ensuring staff fully adopt new systems or processes.
								</td>
							</tr>
							<tr>
								<td>CHI Number</td>
								<td width="70%">
									Community Health Index Number. See Community Health Index
									Number.
								</td>
							</tr>
							<tr>
								<td>Chief Clinical Information Officer</td>
								<td width="70%">
									A senior member of the clinical team assigned to IT and/or
									informatics functions to ensure that any programmes are
									beneficial from a clinical viewpoint.
								</td>
							</tr>
							<tr>
								<td>Clinical Applications</td>
								<td width="70%">
									Computer software products that coordinate and integrate
									all the inherent activities involved in the management and
									running of a healthcare facility. E.g. Electronic Health
									Record systems, order entry systems and e-prescribing
									systems.
								</td>
							</tr>
							<tr>
								<td>Clinical Decision Support</td>
								<td width="70%">
									A health information technology system that is designed to
									assist physicians and other healthcare professionals with
									clinical decision-making tasks.
								</td>
							</tr>
							<tr>
								<td>Clinical Governance</td>
								<td width="70%">
									Clinical governance is the system through which NHS
									organisations are accountable for continuously improving
									the quality of their services and safeguarding high
									standards of care by creating an environment in which
									clinical excellence will flourish.
								</td>
							</tr>
							<tr>
								<td>Clinical Information</td>
								<td width="70%">
									Information about a patient’s health history, past and
									current treatments, prescriptions etc.
								</td>
							</tr>
							<tr>
								<td>Clinical Safety Assessment</td>
								<td width="70%">
									A framework within which the clinical risks associated with
									the deployment and implementation of a new or modified
									health IT system are properly managed.
								</td>
							</tr>
							<tr>
								<td>Co-morbidity</td>
								<td width="70%">
									The presence of one or more additional disorders (or
									diseases) co-occurring with a primary disease or disorder;
									or the effect of such additional disorders or diseases.
								</td>
							</tr>
							<tr>
								<td>Community Health Index (CHI) number</td>
								<td width="70%">
									A unique 10 character numeric Scottish health identifier.
								</td>
							</tr>
							<tr>
								<td>Contraindication</td>
								<td width="70%">
									Conditions under which a certain drug or treatment should
									not be used.
								</td>
							</tr>
							<tr>
								<td>Control Objectives for IT (COBIT)</td>
								<td width="70%">
									An IT governance framework and toolset that allows managers
									to bridge the gap between control requirements, technical
									issues and business risks.
								</td>
							</tr>
							<tr>
								<td>Cranfield Process of Benefits Management</td>
								<td width="70%">
									A benefits realisation management methodology aimed at
									managing how time and resources are invested into making
									desirable changes.
								</td>
							</tr>
							<tr>
								<td>Cyber Security</td>
								<td width="70%">
									Collective term for processes and methods designed to
									protect it systems against malicious access.
								</td>
							</tr>
							<tr>
								<td>Cyber Threat</td>
								<td width="70%">
									Collective term for malicious or unauthorised use of IT
									systems.
								</td>
							</tr>
							<tr>
								<td>Data Extraction</td>
								<td width="70%">
									Data extraction is the analysis and crawling of data to
									retrieve relevant information from sources (e.g.,
									databases) in a specific pattern.
								</td>
							</tr>
							<tr>
								<td>Data Protection by Design</td>
								<td width="70%">
									Systems and technology should be designed and implemented
									in such a way so as to ensure that: (i) data processing is
									limited to what is necessary for the purpose for which the
									data was collected; and, (ii) only those within an
									organisation who need to access the personal data can do
									so. &nbsp;
								</td>
							</tr>
							<tr>
								<td>Decision Support</td>
								<td width="70%">
									A health information technology system that is designed to
									assist physicians and other health professionals with
									decision-making tasks.
								</td>
							</tr>
							<tr>
								<td>Demographic Data</td>
								<td width="70%">See demographic information.</td>
							</tr>
							<tr>
								<td>Demographic Information</td>
								<td width="70%">
									Basic person information, for example name, address,
									gender, age, date of birth, phone number etc.
								</td>
							</tr>
							<tr>
								<td>Diagnostic Test</td>
								<td width="70%">
									A test to aid healthcare professionals in diagnosis. Most
									common types are radiology/imaging and lab/pathology.
								</td>
							</tr>
							<tr>
								<td>Digital Agenda</td>
								<td width="70%">
									A summary term for any plans, strategies, policies,
									objectives or initiatives aimed at improving the use of
									digital technology.
								</td>
							</tr>
							<tr>
								<td>Digital Alerts</td>
								<td width="70%">
									A message delivered to its recipient digitally. This
									usually refers to messages generated automatically by
									systems based on pre-defined rules.
								</td>
							</tr>
							<tr>
								<td>Digital Asset Tracking</td>
								<td width="70%">
									The process of managing the assets of the organisation
									digitally.
								</td>
							</tr>
							<tr>
								<td>Digital Assets</td>
								<td width="70%">
									Collective term for digital systems, information, processes
									and services.
								</td>
							</tr>
							<tr>
								<td>Digital Care Record</td>
								<td width="70%">
									A digital collection of documents created in the course of
									a person’s interaction with care providers, such as
									diagnoses, treatments, preferences etc.
								</td>
							</tr>
							<tr>
								<td>Digital Clinical Workflow</td>
								<td width="70%">
									The sequence of digital processes through which patient
									information passes in the course of providing care.
								</td>
							</tr>
							<tr>
								<td>Digital Formats</td>
								<td width="70%">
									Collective term for data formats used for digital storage
									or processing.
								</td>
							</tr>
							<tr>
								<td>Digital Imagery</td>
								<td width="70%">
									The digital rendition of diagnostic imagery, including
									digital pictures used in dermatology as well as images from
									X-rays, CT scans etc.
								</td>
							</tr>
							<tr>
								<td>Digital Literacy</td>
								<td width="70%">
									Digital literacy is the set of competencies including
									knowledge, skills, and behaviours involving the effective
									use of digital devices such as smartphones, tablets,
									laptops and desktop PCs.
								</td>
							</tr>
							<tr>
								<td>Digital Patient Flow</td>
								<td width="70%">
									The process of optimising care facilities by managing the
									flow of patients through stages of care digitally.
								</td>
							</tr>
							<tr>
								<td>Digital Prescribing</td>
								<td width="70%">
									The process of issuing and managing prescriptions
									digitally.
								</td>
							</tr>
							<tr>
								<td>Digital Rostering</td>
								<td width="70%">
									The process of managing staff rotas and shifts digitally.
								</td>
							</tr>
							<tr>
								<td>Digital Strategy</td>
								<td width="70%">
									The digital strategy outlines plans to digitally enable an
									organisation to meet its business/clinical objectives.
								</td>
							</tr>
							<tr>
								<td>Digital Technology</td>
								<td width="70%">
									Technology based on information stored in binary (0 and 1)
									code.
								</td>
							</tr>
							<tr>
								<td>Digital Transformation</td>
								<td width="70%">
									Digital transformation is the integration of digital
									technology into all areas of a business, fundamentally
									changing how the organisation operates and delivers value
									to patients/clients.
								</td>
							</tr>
							<tr>
								<td>Digital Workflows</td>
								<td width="70%">
									A sequence of tasks or actions capable of being completed
									digitally.
								</td>
							</tr>
							<tr>
								<td>Disaster Recovery</td>
								<td width="70%">
									Disaster recovery involves a set of policies, tools and
									procedures to enable the recovery or continuation of vital
									technology infrastructure and systems following a natural
									or human-induced disaster<strong>.</strong>
								</td>
							</tr>
							<tr>
								<td>Discharge Summary</td>
								<td width="70%">
									A summary of a patient’s details, care activities,
									prescriptions and other information issued to the patient
									as well as his/her GP upon hospital discharge.
								</td>
							</tr>
							<tr>
								<td>Drug: drug Interactions</td>
								<td width="70%">
									Drug-drug interactions occur when a drug interacts, or
									interferes, with another drug.
								</td>
							</tr>
							<tr>
								<td>Early Warning Score</td>
								<td width="70%">
									A guide used by healthcare professionals to quickly
									determine the degree of illness of a patient.
								</td>
							</tr>
							<tr>
								<td>Electronic Patient Record (EPR)</td>
								<td width="70%">
									Electronically stored patient information in a digital
									format. These records can be shared across different health
									care settings.
								</td>
							</tr>
							<tr>
								<td>Emergency Care Summary</td>
								<td width="70%">
									Emergency Care Summary provides up to date information
									about allergies and GP prescribed medication for use in
									care contexts where clinicians do not have access to the
									core GP record.
								</td>
							</tr>
							<tr>
								<td>Executive Sponsor</td>
								<td width="70%">
									A board member designated to representing individual
									initiatives at board level.
								</td>
							</tr>
							<tr>
								<td>Formatted Text</td>
								<td width="70%">
									Text for which styling information beyond the minimum of
									semantic elements exists: colours, styles (boldface,
									italic), sizes, and special features (such as hyperlinks).
								</td>
							</tr>
							<tr>
								<td>GDPR</td>
								<td width="70%">General Data Protection Regulation</td>
							</tr>
							<tr>
								<td>General Data Protection Regulation</td>
								<td width="70%">
									The General Data Protection Regulation is a legal framework
									that sets guidelines for the collection and processing of
									personal information of individuals within the European
									Union. GDPR came into effect across the EU on May 25th
									2018.
								</td>
							</tr>
							<tr>
								<td>GS1</td>
								<td width="70%">
									<table width="100%">
										<tbody>
											<tr>
												<td width="316">
													A standard for producing and reading barcodes.
													<a href="http://www.gs1.org/healthcare/"
														>http://www.gs1.org/healthcare/</a
													>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td>Handover of Care</td>
								<td width="70%">
									A type of transition of care; specifically means
									organisation-internal transition of care.
								</td>
							</tr>
							<tr>
								<td>Health and Care Professional</td>
								<td width="70%">
									An individual certified/employed to perform health or care
									activities.
								</td>
							</tr>
							<tr>
								<td>Healthcare Professional</td>
								<td width="70%">See health and care professional.</td>
							</tr>
							<tr>
								<td>Horizon Scanning</td>
								<td width="70%">
									A method of collecting and organising information about
									emerging technologies.
								</td>
							</tr>
							<tr>
								<td>Incident Management Process</td>
								<td width="70%">
									Incident management is a defined process for logging,
									recording and resolving incidents. The aim of incident
									management is to restore the service to the customer as
									quickly as possible.
								</td>
							</tr>
							<tr>
								<td>Information Governance</td>
								<td width="70%">
									Information governance (IG) is the way in which information
									is handled by organisations to ensure personal and
									sensitive information is held, managed, shared and
									processed appropriately in line with legal and best
									practice standards.
								</td>
							</tr>
							<tr>
								<td>Innovate UK</td>
								<td width="70%">
									Innovate UK is an executive non-departmental public body,
									sponsored by the Department for Business, Energy &amp;
									Industrial Strategy. Innovate UK drives productivity and
									growth by supporting businesses to realise the potential of
									new technologies, develop ideas and make them a commercial
									success.
								</td>
							</tr>
							<tr>
								<td>Instant Messaging</td>
								<td width="70%">
									A method for sending messages to others transmitted via the
									internet instantly.
								</td>
							</tr>
							<tr>
								<td>IT Infrastructure Library (ITIL)</td>
								<td width="70%">An IT service management methodology.</td>
							</tr>
							<tr>
								<td>Kanban</td>
								<td width="70%">
									Kanban is a lean method to manage and improve work across
									human systems. This approach aims to manage work by
									balancing the demands with available capacity, and
									improving the handling of system level bottlenecks.
								</td>
							</tr>
							<tr>
								<td>Key Information Summary</td>
								<td width="70%">
									The Key Information Summary is a summary of medical history
									and patient wishes taken from the GP electronic record.
									This includes medication; allergies and adverse reactions
									to medication; care plans; next of kin and carer details;
									patient wishes; long term condition management plans;
									preferred place of care.
								</td>
							</tr>
							<tr>
								<td>Lean</td>
								<td width="70%">
									The main principle of lean project management is delivering
									more value with less waste in a project context. &nbsp;
								</td>
							</tr>
							<tr>
								<td>Local Formulary</td>
								<td width="70%">
									A list of medicines and treatments authorised by the
									management of an organisation.
								</td>
							</tr>
							<tr>
								<td>Managing Successful Programmes (MSP)</td>
								<td width="70%">
									MSP is a best practice method for programme management that
									enables organisations to coordinate, direct, implement, and
									manage a portfolio of projects that deliver a desired
									outcome.
								</td>
							</tr>
							<tr>
								<td>Medication Administration</td>
								<td width="70%">
									The process of delivering/giving medication to a patient.
								</td>
							</tr>
							<tr>
								<td>Medicines Reconciliation</td>
								<td width="70%">
									A formal process of obtaining and verifying a complete and
									accurate list of each patient’s current medicines.
								</td>
							</tr>
							<tr>
								<td>Metadata</td>
								<td width="70%">
									A set of data that describes and gives information about
									other data.
								</td>
							</tr>
							<tr>
								<td>NMAHP</td>
								<td width="70%">
									Nurse, Midwife or Allied Health Professional. See Allied
									Health Professional
								</td>
							</tr>
							<tr>
								<td>Online Meetings</td>
								<td width="70%">
									A method of conducting meetings with others using
									internet-based telecommunication media, such as Voice over
									IP or Video Chat.
								</td>
							</tr>
							<tr>
								<td>Online Portal</td>
								<td width="70%">
									An online portal is most often a specially designed web
									site that brings information together from diverse sources
									in a uniform way. Usually each information source gets its
									dedicated area on the page for displaying information (a
									portlet); often, the user can configure which portlet to
									display.
								</td>
							</tr>
							<tr>
								<td>Order</td>
								<td width="70%">
									A request that another department (i.e. radiology,
									laboratory, pharmacy) carry out the tasks described in the
									request.
								</td>
							</tr>
							<tr>
								<td>Order Set</td>
								<td width="70%">
									A grouping of orders commonly placed together; often also
									includes links to reference materials, such as formularies
									or clinical evidence.
								</td>
							</tr>
							<tr>
								<td>Patient Consultation</td>
								<td width="70%">
									A meeting between a healthcare professional and a patient
									for assessment or therapeutic purposes.
								</td>
							</tr>
							<tr>
								<td>Patient/Client Preferences</td>
								<td width="70%">
									Recorded preferences a person may have; usually includes
									preferences for resuscitation, life support and desired
									place of care.
								</td>
							</tr>
							<tr>
								<td>Patient Record Forms</td>
								<td width="70%">
									Document used to record interactions with a patient with
									the objective of adding them to the patient record.
								</td>
							</tr>
							<tr>
								<td>Penetration Testing</td>
								<td width="70%">
									Penetration testing (also called pen testing) is the
									practice of testing a computer system, network or web
									application to find vulnerabilities that an attacker could
									exploit.
								</td>
							</tr>
							<tr>
								<td>Point of Care</td>
								<td width="70%">
									The place of interaction between a health and care
									professional and a patient/client.
								</td>
							</tr>
							<tr>
								<td>Population Health</td>
								<td width="70%">
									Population health means the health and wellbeing outcomes
									of a defined group of people, as well as the distribution
									of health and wellbeing outcomes within the group.
								</td>
							</tr>
							<tr>
								<td>Pre-Population</td>
								<td width="70%">
									The automatic populating of fields in forms from data
									already held digitally.
								</td>
							</tr>
							<tr>
								<td>Prescription</td>
								<td width="70%">
									A prescriber’s request and authorisation to issue medicines
									or other treatments to a patient.
								</td>
							</tr>
							<tr>
								<td>Prince 2</td>
								<td width="70%">
									Prince 2 is a structured project management method and
									practitioner certification programme.
								</td>
							</tr>
							<tr>
								<td>PRSB</td>
								<td width="70%">
									Professional Record Standards Body. An organisation that
									works with the public and with professionals providing care
									to define the standards needed for good care records and so
									to improve the safety and quality of health and social
									care.
									<a href="https://theprsb.org/standards/"
										>https://theprsb.org/standards/</a
									>
								</td>
							</tr>
							<tr>
								<td>Qualitative Benefits Identification</td>
								<td width="70%">
									The process of discovering and documenting unquantifiable
									benefits from an initiative.
								</td>
							</tr>
							<tr>
								<td>Quantitative Benefits Identification</td>
								<td width="70%">
									The process of discovering and documenting quantifiable
									benefits from an initiative.
								</td>
							</tr>
							<tr>
								<td>Reference Sources</td>
								<td width="70%">
									Usually third party information underpinning treatment
									protocols with scientific evidence.
								</td>
							</tr>
							<tr>
								<td>Remote / Virtual Clinical Consultation</td>
								<td width="70%">
									A clinical consultation where the physical presence of a
									health or care professional is replaced by a
									telecommunication medium.
								</td>
							</tr>
							<tr>
								<td>Remote Monitoring</td>
								<td width="70%">
									A method for continuously collecting data from devices not
									physically present via telecommunication media.
								</td>
							</tr>
							<tr>
								<td>Remote/Virtual Delivery of Treatment</td>
								<td width="70%">
									A clinical treatment where the physical presence of a
									health care professional is replaced by a telecommunication
									medium.
								</td>
							</tr>
							<tr>
								<td>RFID</td>
								<td width="70%">
									A technology that incorporates the use of electromagnetic
									or electrostatic coupling in the radio frequency (rf)
									portion of the electromagnetic spectrum to uniquely
									identify an object, or person.
								</td>
							</tr>
							<tr>
								<td>Risk Stratification</td>
								<td width="70%">
									Risk Stratification can help to identify and support
									management of patients and cohorts of people in local
									populations who are at a higher risk of a number of key
									outcomes.
								</td>
							</tr>
							<tr>
								<td>Self-Directed Support</td>
								<td width="70%">
									Self- directed support allows people to choose how their
									support is provided, and gives them as much control as they
									want of their individual budget.
								</td>
							</tr>
							<tr>
								<td>Semi-structured data</td>
								<td width="70%">
									Includes both unstructured information as well as
									structured data items (and associated metadata) organised
									in a pre-defined manner (e.g. in databases, information
									systems, XML files)
								</td>
							</tr>
							<tr>
								<td>Senior Information Risk Owner (SIRO)</td>
								<td width="70%">
									A Senior Information Risk Owner is an Executive Director or
									member of the Senior Management Board of an organisation
									with overall responsibility for an organisation's
									information risk policy. The SIRO is accountable and
									responsible for information risk across the organisation.
								</td>
							</tr>
							<tr>
								<td>Service Transformation</td>
								<td width="70%">
									The process of changing how services are delivered from
									developing a case for change through to implementing new
									service delivery models with staff engagement and ensuring
									benefits are realised.
								</td>
							</tr>
							<tr>
								<td>Single Sign-on Access</td>
								<td width="70%">
									A central authentication system which means that users do
									not have to remember and use multiple usernames and
									passwords to navigate between different applications.
								</td>
							</tr>
							<tr>
								<td>Small Business Research Initiative (SBRI)</td>
								<td width="70%">
									SBRI brings together government challenges and ideas from
									business to create innovative solutions.
								</td>
							</tr>
							<tr>
								<td>Structured data</td>
								<td width="70%">
									Structured data are organised in a pre-defined manner
									(e.g., in databases, information systems, XML files). This
									can include free text sections, but those are complemented
									by adequate metadata information as well as structured data
									items (and associated metadata) organised in a pre-defined
									manner (e.g. in databases, information systems, XML files).
								</td>
							</tr>
							<tr>
								<td>Skype</td>
								<td width="70%">A provider of videoconferencing media.</td>
							</tr>
							<tr>
								<td>SNOMED-CT</td>
								<td width="70%">
									A standardised, multilingual vocabulary of clinical
									terminology that is used by physicians and other health
									care providers for the electronic exchange of clinical
									health information.
									<a href="https://digital.nhs.uk/snomed-ct"
										>https://digital.nhs.uk/snomed-ct</a
									>
								</td>
							</tr>
							<tr>
								<td>Software Asset &amp; License Register</td>
								<td width="70%">
									A set of records detailing titles, versions, license
									details and purchase information for software products
									owned by an entity.
								</td>
							</tr>
							<tr>
								<td>Structured Data Format</td>
								<td width="70%">
									Collective term for data storage methods that allow
									programmatic access and use of the data stored; commonly,
									this includes storing meta data (information about the data
									stored) together with the actual data.
								</td>
							</tr>
							<tr>
								<td>Telecare</td>
								<td width="70%">
									Telecare uses discreet electronic equipment and sensors
									around the home to detect risks such as fires or falls.
								</td>
							</tr>
							<tr>
								<td>Transition of Care</td>
								<td width="70%">
									The process of transferring responsibility for the care of
									a patient to another healthcare professional or another
									organisation.
								</td>
							</tr>
							<tr>
								<td>Unstructured data</td>
								<td width="70%">
									Data are held in an aggregated form (e.g., Word, Excel,
									PDF); individual data items are not organised in a
									pre-defined manner and cannot be accessed programmatically.
									No metadata is associated.
								</td>
							</tr>
							<tr>
								<td>Videoconferencing</td>
								<td width="70%">
									A method of conducting meetings with multiple others using
									audio/video telecommunications media.
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</template>
	</content-page>
</template>
<script>
import ContentPage from "@c/ui/ContentPage"
export default {
	name: "GlossaryAndDefinitions",
	components: {
		ContentPage
	}
};
</script>
