<template>
	<content-page>
		<template v-slot:content>
			<v-sheet flat>
				<content-contents :value="sidebar" :active="active">
					<template v-slot:prepend>
						<p>
							You can find instructions for the most important functions of this
							tool on this page. Need more help? Get in touch with
							<a href="#helpsupport">support!</a>
						</p>
					</template>
				</content-contents>

				<h2 class="pl-5 mb-2">Tutorials</h2>
				<content-list :value="content" @focused="(id) => (active = id)">
					<template v-slot:table1>
						<table1></table1>
					</template>
					<template v-slot:table2>
						<table2></table2>
					</template>
					<template v-slot:table3>
						<table3></table3>
					</template>
					
					<template v-slot:staffsurveytable>
						<StaffSurveyContent></StaffSurveyContent>
					</template>
				</content-list>
			</v-sheet>
		</template>
	</content-page>
</template>

<script>
	import Table1 from "@c/tables/Table1";
	import Table2 from "@c/tables/Table2";
	import StaffSurveyContent from "@c/tables/StaffSurveyContent";
	import Table3 from "@c/tables/Table3";
	import ContentContents from "@c/ui/ContentContents";
	import ContentList from "@c/ui/ContentList";
	import ContentPage from "@c/ui/ContentPage";
	export default {
		name: "Tutorials",
		props: {},
		data: () => {
			return {
				active: null,
				content: [
					{type: "content", id: "fundamentals"},
					{type: "content", id: "settingupyourassessment"},
					{type: "slot", name: "table1"},
					{type: "content", id: "planningyourdigitalmaturitysubmission"},
					{type: "content", id: "usingthecollaborationfeatures1"},
					{type: "slot", name: "table2"},
					{type: "content", id: "usingthecollaboratiofeatures2"},
					{type: "slot", name: "staffsurveytable"},
					{type: "content", id: "thesurveyworkspace1"},
					{type: "slot", name: "table3"},
					{type: "content", id: "thesurveyworkspace2"},
					{type: "content", id: "evidenceandnotes"},
					{type: "content", id: "accessingassessmentdata"},
					{type: "content", id: "helpsupport"}
				],
				sidebar: [
					{title: "Fundamentals", ids: ["fundamentals"]},
					{
						title: "Setting Up Your Assessment",
						ids: ["settingupyourassessment"]
					},
					{
						title: "Planning Your Digital Maturity Submission",
						ids: ["planningyourdigitalmaturitysubmission"]
					},
					{
						title: "Using the Collaboration Features",
						ids: [
							"usingthecollaborationfeatures1",
							"usingthecollaboratiofeatures2"
						]
					},
					{
						title: "The Survey Workspace",
						ids: ["thesurveyworkspace1", "thesurveyworkspace2"]
					},
					{title: "Evidence and Notes", ids: ["evidenceandnotes"]},
					{title: "Acessing Assessment Data", ids: ["accessingassessmentdata"]},
					{title: "Help and Support", ids: ["helpsupport"]}
				]
			};
		},
		components: {
			ContentPage,
			ContentContents,
			ContentList,
			Table1,
			Table2,
			Table3,
			StaffSurveyContent
		},
		computed: {}
	};
</script>
